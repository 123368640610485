import axios from 'axios';

// Categorias válidas do sistema
export const CATEGORIAS_VALIDAS = [
  'vegetariano',
  'vegano',
  'sobremesa',
  'prato principal',
  'cafe da manha',
  'lanche',
  'snack',
  'doces',
  'frances',
  'chocolate',
  'cookies',
  'bebida',
  'bolo',
  'torta'
] as const;

export type CategoriaValida = typeof CATEGORIAS_VALIDAS[number];

// Interface para as receitas
export interface ReceitaSimples {
  id: number;
  titulo: string;
  descricao: string;
  ingredientes: string[];
  modoPreparo: string[];
  tempoPreparo: number;
  porcoes: number;
  vegetariana: boolean;
  vegana: boolean;
  criadoEm: string;
  atualizadoEm: string;
  dificuldade: string | null;
  categoria_principal: string;
  categorias: string[];
  dicas: string[] | null;
  calorias: number | null;
  proteinas: number | null;
  carboidratos: number | null;
  gorduras: number | null;
  fibras: number | null;
  foto_principal: string | null;
  fotos: string[] | null;
  video_url: string | null;
  is_destaque: boolean;
  is_publicada: boolean;
  visualizacoes: number;
  photographer?: string;
  sourceUrl?: string;
  license?: string;
  author?: string;
  imagem?: string;
  categoria?: string;
}

export interface PaginatedResponse {
  data: ReceitaSimples[];
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

export interface GetReceitasParams {
  pagina?: number;
  categoria?: CategoriaValida;
  termoBusca?: string;
  ordenacao?: 'asc' | 'desc';
  limit?: number;
  destaque?: boolean;
  ordenar?: string;
}

// Obter a URL da API do arquivo .env
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

// Lista de domínios permitidos
const DOMINIOS_PERMITIDOS = process.env.NODE_ENV === 'development'
  ? ['localhost:3000', 'localhost:5000']
  : [
      'receitasverdes.com.br',
      'www.receitasverdes.com.br',
      'rollback-receitas-verdes.vercel.app',
      'sea-lion-app-76fxj.ondigitalocean.app',
      'frozen-ridge-08812-ccd469cf8239.herokuapp.com',
      'king-prawn-app-9p85q.ondigitalocean.app'
    ];

// Função para validar origem da requisição
const validarOrigem = (url: string): boolean => {
  try {
    // Em desenvolvimento, permite todas as origens
    if (process.env.NODE_ENV === 'development') {
      return true;
    }
    
    // Se a URL não começar com http:// ou https://, adiciona https://
    const urlCompleta = url.startsWith('http') ? url : `https://${url}`;
    const origem = new URL(urlCompleta).hostname;
    
    return DOMINIOS_PERMITIDOS.some(dominio => 
      origem === dominio || origem.endsWith(`.${dominio}`)
    );
  } catch (error) {
    console.error('Erro ao validar origem:', error);
    return false;
  }
};

// Adicionar log para debug
//console.log('API URL:', API_URL);
//console.log('Ambiente:', process.env.NODE_ENV);

// Interface para contagem de requisições
interface RequestCounter {
  url: string;
  count: number;
  params: Record<string, any>;
  lastTimestamp: string;
  requestsPerMinute: number[];
  totalBytes: number;
  component?: string;
  errors: {
    count: number;
    lastError?: {
      message: string;
      status: number;
      timestamp: string;
    };
  };
  performance: {
    avgResponseTime: number;
    totalResponseTime: number;
    samples: number;
  };
}

// Objeto para armazenar contadores por URL
const requestCounters: Record<string, RequestCounter> = {};

// Função para extrair parâmetros da URL
const extractParamsFromUrl = (url: string): Record<string, any> => {
  try {
    const [, queryString] = url.split('?');
    if (!queryString) return {};

    const params: Record<string, any> = {};
    const searchParams = new URLSearchParams(queryString);
    
    searchParams.forEach((value, key) => {
      // Converte valores numéricos
      if (!isNaN(Number(value))) {
        params[key] = Number(value);
      } else if (value === 'true' || value === 'false') {
        params[key] = value === 'true';
      } else {
        params[key] = value;
      }
    });

    return params;
  } catch (error) {
    console.error('Erro ao extrair parâmetros:', error);
    return {};
  }
};

// Função para calcular o tamanho da resposta em bytes
const calculateResponseSize = (response: any): number => {
  try {
    return new Blob([JSON.stringify(response)]).size;
  } catch (error) {
    return 0;
  }
};

// Função para incrementar contador e exibir tabela
const trackRequest = (url: string, params: Record<string, any>, component?: string) => {
  const now = new Date();
  const currentMinute = now.getMinutes();

  if (!requestCounters[url]) {
    requestCounters[url] = {
      url,
      count: 0,
      params: extractParamsFromUrl(url),
      lastTimestamp: now.toISOString(),
      requestsPerMinute: new Array(60).fill(0),
      totalBytes: 0,
      component,
      errors: { count: 0 },
      performance: {
        avgResponseTime: 0,
        totalResponseTime: 0,
        samples: 0
      }
    };
  }

  const counter = requestCounters[url];
  counter.count++;
  counter.params = extractParamsFromUrl(url);
  counter.lastTimestamp = now.toISOString();
  counter.requestsPerMinute[currentMinute] = (counter.requestsPerMinute[currentMinute] || 0) + 1;
  counter.component = component || counter.component;

  // Calcular requisições por minuto
  const requestsLastMinute = counter.requestsPerMinute.reduce((a, b) => a + b, 0);
  const warningThreshold = 30;

  // Formata os dados para a tabela
  const tableData = Object.values(requestCounters).map(counter => ({
    'URL Base': counter.url.split('?')[0],
    'Componente': counter.component || 'Desconhecido',
    'Total Requisições': counter.count,
    'Req/Minuto': requestsLastMinute,
    'Bytes Transferidos': `${(counter.totalBytes / 1024).toFixed(2)} KB`,
    'Tempo Médio': `${counter.performance.avgResponseTime.toFixed(2)}ms`,
    'Taxa de Erro': `${((counter.errors.count / counter.count) * 100).toFixed(1)}%`,
    'Última Requisição': new Date(counter.lastTimestamp).toLocaleTimeString('pt-BR')
  }));

  //console.table(tableData);

  // Alertas mais detalhados
  if (requestsLastMinute > warningThreshold) {
    console.warn(`⚠️ ALERTA: Alto número de requisições detectado!
      URL: ${url}
      Componente: ${counter.component || 'Desconhecido'}
      Requisições no último minuto: ${requestsLastMinute}
      Taxa de erro: ${((counter.errors.count / counter.count) * 100).toFixed(1)}%
      Tempo médio de resposta: ${counter.performance.avgResponseTime.toFixed(2)}ms
      Último erro: ${counter.errors.lastError ? 
        `${counter.errors.lastError.message} (${counter.errors.lastError.status})` : 'Nenhum'}
    `);
  }
};

const api = axios.create({
  baseURL: API_URL,
  timeout: 10000, // 10 segundos
  withCredentials: true, // Habilitar envio de credenciais
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Origin': window.location.origin
  }
});

// Interceptor para requisições
api.interceptors.request.use(
  (config) => {
    const component = new Error().stack?.split('\n')[3]?.trim() || 'Desconhecido';
    const startTime = Date.now();
    
    // Log dos headers que estão sendo enviados
    console.log('Headers sendo enviados:', config.headers);
    
    // Em desenvolvimento, não valida a origem
    if (process.env.NODE_ENV !== 'development') {
      // Pega a URL completa da requisição
      const urlCompleta = `${config.baseURL}${config.url}`;
      if (!validarOrigem(urlCompleta)) {
        console.error('Tentativa de requisição de origem não permitida:', urlCompleta);
        return Promise.reject(new Error('Origem não permitida'));
      }
    }
    
    // Adicionar tempo de início à config para calcular duração
    (config as any).startTime = startTime;
    
    // Adicionar headers de segurança
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    config.headers['Origin'] = window.location.origin;
    
    console.log('Request:', config.url, config.params, 'Componente:', component);
    if (config.method?.toLowerCase() === 'get') {
      trackRequest(config.url || '', config.params || {}, component);
    }
    return config;
  },
  (error) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// Interceptor para respostas
api.interceptors.response.use(
  (response) => {
    // Log da resposta
    //console.log('Response Headers:', response.headers);
    //console.log('Response Status:', response.status);
    
    const counter = requestCounters[response.config.url || ''];
    if (counter) {
      counter.totalBytes += calculateResponseSize(response.data);
      
      // Calcular tempo de resposta
      const duration = Date.now() - (response.config as any).startTime;
      counter.performance.totalResponseTime += duration;
      counter.performance.samples++;
      counter.performance.avgResponseTime = 
        counter.performance.totalResponseTime / counter.performance.samples;
    }
    
    return response;
  },
  (error) => {
    // Log detalhado do erro
    console.error('Response Error:', {
      url: error.config?.url,
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
      headers: error.config?.headers
    });
    
    const counter = requestCounters[error.config?.url || ''];
    if (counter) {
      counter.errors.count++;
      counter.errors.lastError = {
        message: error.message,
        status: error.response?.status,
        timestamp: new Date().toISOString()
      };
    }
    
    return Promise.reject(error);
  }
);

// Limpar contadores a cada hora para evitar vazamento de memória
setInterval(() => {
  Object.keys(requestCounters).forEach(key => {
    requestCounters[key].requestsPerMinute = new Array(60).fill(0);
  });
}, 60 * 60 * 1000);

export const ReceitasService = {
  async getReceitas({
    pagina = 1,
    categoria,
    termoBusca,
    ordenacao = 'desc',
    limit = 10,
    destaque,
    ordenar
  }: GetReceitasParams = {}): Promise<PaginatedResponse> {
    const params = new URLSearchParams();

    // Parâmetros obrigatórios
    params.append('page', pagina.toString());
    params.append('limit', limit.toString());

    // Parâmetros opcionais
    if (ordenar) {
      params.append('ordenar', ordenar);
    } else if (ordenacao) {
      // Convertendo 'asc' e 'desc' para o formato correto
      params.append('ordenar', ordenacao === 'desc' ? '-id' : 'id');
    }

    if (categoria) {
      params.append('categoria', encodeURIComponent(categoria));
    }

    // Tratamento do termo de busca
    if (termoBusca) {
      const termoBuscaTrimmed = termoBusca.trim();
      if (termoBuscaTrimmed) {
        params.append('busca', encodeURIComponent(termoBuscaTrimmed));
      }
    }

    if (destaque) {
      params.append('destaque', 'true');
    }

    const url = `/receitas?${params.toString()}`;

    try {
      const response = await api.get<PaginatedResponse>(url);
      return response.data;
    } catch (error) {
      throw new Error('Erro ao buscar receitas');
    }
  },

  getImagemUrl(foto_principal: string): string {
    return foto_principal;
  },

  async getReceitaPorId(id: string | number): Promise<ReceitaSimples> {
    try {
      const response = await api.get<ReceitaSimples>(`/receitas/${id}`);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  }
};

export default ReceitasService;